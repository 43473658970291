import React from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import rocketIcon from '../../images/svg/rocket-icon.svg';
import mountainIcon from '../../images/svg/mountain-icon.svg';
import moneyIcon from '../../images/svg/money-icon.svg';

const features = [
  {
    icon: rocketIcon,
    iconWidth: 55,
    iconHeight: 64,
    heading: 'Make our services your services',
    description: (
      <p>
        Our partner processes are designed to integrate seamlessly with your
        agency, so you can better address the web operations needs of your
        clients. Present our web operations services as part of your own
        offerings, and easily scale our expert services up and down to adapt to
        changing project requirements.
      </p>
    ),
  },
  {
    icon: mountainIcon,
    iconWidth: 98,
    iconHeight: 64,
    heading: 'Don’t build new processes — leverage proven ones',
    description: (
      <p>
        With Marvel &amp; Snap, you get one point of contact that brings years
        of experience to any web project. Plus, you can leverage our focus on
        current web tools, techniques, and best practices to offer expert
        development, industry-leading hosting, and proactive ongoing website
        management to your clients.
      </p>
    ),
  },
  {
    icon: moneyIcon,
    iconWidth: 67,
    iconHeight: 67,
    heading: 'Enjoy preferred pricing on every project',
    description: (
      <p>
        Our agency partners have exclusive access to preferred pricing on web
        development projects, hosting plans, and ongoing monthly care packages.
        And, they enjoy the ability to flexibly scale each of our services up or
        down as needed.
      </p>
    ),
  },
];

const useFeatureStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    '& [class*="MuiGrid-container"]': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  first: {
    [theme.breakpoints.up('lg')]: {
      '& [class*="makeStyles-heading"]': {
        maxWidth: 360,
      },
    },
  },
  middle: {
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.only('md')]: {
      position: 'relative',
      '&::before': {
        backgroundColor: theme.palette.primary.dark,
        content: '""',
        display: 'block',
        height: '100%',
        left: '50%',
        position: 'absolute',
        top: 0,
        transform: 'translateX(-50%)',
        width: '100vw',
        zIndex: 1,
      },
    },
  },
  sizer: {
    margin: 'auto',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 410,
    },
  },
  icon: {
    '& svg': {
      display: 'block',
      height: 64,
      width: 'auto',
    },
  },
  heading: {
    color: 'white',
    fontFamily: 'Visby CF',
    margin: '2rem 0',
    '& small': {
      fontSize: '.75em',
    },
  },
  description: {
    color: 'white',
    '& p': {
      margin: '2rem auto',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

const PartnersFeatures: React.FC = () => {
  const featureStyles = useFeatureStyles();

  return (
    <Box className={featureStyles.root}>
      <Container>
        <Grid container spacing={10} alignItems="flex-start" justify="center">
          {features.map((feature, index) => {
            let featureClass: string | undefined = undefined;

            if (index === 0) {
              featureClass = featureStyles.first;
            } else if (index === 1) {
              featureClass = featureStyles.middle;
            }

            return (
              <Grid item className={featureClass} key={index} xs={12} lg={4}>
                <Box py={3}>
                  <div className={featureStyles.sizer}>
                    <img
                      className={featureStyles.icon}
                      src={feature.icon}
                      alt=""
                      width={feature.iconWidth}
                      height={feature.iconHeight}
                    />
                    <Typography
                      className={featureStyles.heading}
                      variant="h3"
                      color="textSecondary"
                    >
                      {feature.heading}
                    </Typography>
                    <Typography
                      className={featureStyles.description}
                      variant="body2"
                      component="div"
                    >
                      {feature.description}
                    </Typography>
                  </div>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default PartnersFeatures;

import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Button from '../components/mui/button';

import Layout from '../components/global/layout';
import SEO from '../components/seo';

import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';

import PageBanner from '../components/layouts/page-banner';
import PartnersFeatures from '../components/layouts/partners-features';
import CallToAction from '../components/layouts/call-to-action';

import aragonaLogo from '../images/svg/aragona-logo.svg';

const useTestimonialStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
  },
  quote: {
    fontFamily: 'Visby CF',
    fontSize: 40,
    textTransform: 'unset',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
  },
  logo: {
    height: 88,
    marginBottom: theme.spacing(8),
    width: 64,
  },
  author: {
    fontWeight: 600,
    marginTop: theme.spacing(4),
    opacity: 0.75,
  },
}));

const PartnersPage: React.FC = () => {
  const testimonialStyles = useTestimonialStyles();

  return (
    <Layout>
      <SEO
        title="Partner Program for Agencies"
        description="We partner with agencies to enable them to offer fully integrated, enterprise-grade web services to their clients."
      />
      <PageBanner
        heading="Build your business with the best web operations for your clients"
        imageSrc="partners-banner.jpg"
        maxWidth={1200}
      />
      <LightMode>
        <VerticalPadding lg>
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justify="space-between"
            >
              <Grid item xs={12} md={5} lg={4} xl={3}>
                <Typography variant="h2" color="textSecondary">
                  Meet your new web ops team
                </Typography>
              </Grid>
              <Grid item xs={12} md={7} lg={8}>
                <Typography variant="subtitle1" component="p" className="lead">
                  Whether it&apos;s supporting a project that&apos;s already
                  moving or working hand-in-hand from inception to completion,
                  we partner with marketing agencies and enable them to offer
                  fully integrated, enterprise-grade web services to their
                  clients.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </LightMode>
      <PartnersFeatures />
      <Box bgcolor="violet.dark" textAlign="center">
        <VerticalPadding>
          <Container>
            <Grid container justify="center">
              <Grid
                item
                className={testimonialStyles.root}
                xs={12}
                lg={11}
                xl={10}
              >
                <img
                  className={testimonialStyles.logo}
                  src={aragonaLogo}
                  alt="Aragona Agency logo"
                  width="64"
                  height="88"
                />
                <Typography
                  className={testimonialStyles.quote}
                  variant="h2"
                  component="blockquote"
                >
                  &ldquo;The Marvel{' '}
                  <span style={{ fontSize: '0.8em' }}>&amp;</span> Snap team
                  offers the top-tier web operations services and solutions
                  needed to deliver effective online marketing programs and
                  strategies. By partnering with Marvel{' '}
                  <span style={{ fontSize: '0.8em' }}>&amp;</span> Snap, our
                  agency has been able to meet and exceed our clients&apos;
                  needs and expectations much more effectively than any
                  initiative we would have undertaken to develop the web
                  marketing processes and services in-house.&rdquo;
                  <Typography
                    className={testimonialStyles.author}
                    variant="body2"
                    component="footer"
                  >
                    &mdash;&nbsp;Alex Aragona, Program Manager &amp; Strategist
                    at Aragona Agency
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </Box>
      <CallToAction imageSrc="call-to-action-do-more.jpg">
        <Typography
          variant="h2"
          color="textSecondary"
          gutterBottom={true}
          style={{ maxWidth: '12em' }}
        >
          Make web operations one of your key offerings
        </Typography>
        <Typography
          variant="body2"
          style={{ fontSize: 24, marginTop: 40, marginBottom: 50 }}
        >
          Bring your agency&apos;s website services and solutions to the next
          level. Partner with Marvel &amp; Snap to build and launch advanced
          digital experiences for your clients more quickly and efficiently.
        </Typography>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          to="/contact/"
        >
          Let&apos;s talk
        </Button>
      </CallToAction>
    </Layout>
  );
};

export default PartnersPage;
